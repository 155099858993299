<template>
	<v-simple-table dense>
		<thead>
		<tr>
			<th><field-label name="up_type" /></th>
			<th><field-label name="created_time" /></th>
			<th><field-label name="created_by" /></th>
			<th nowrap>Влиза в сила</th>
			<th>Уред</th>
			<th>Причина</th>
			<th>Обработено</th>
			<th>Приключено</th>
			<th><field-label name="file" /></th>
			<th v-if="editPermission"></th>
		</tr>
		</thead>
		<tbody>
		<template v-for="(up_item, up_index) in items">
			<template v-for="(device_item, device_index) in up_item.devices">
				<tr v-if="device_index===0" :key="`${up_index}_${device_index}`">
					<td><field-value name="up_type" :value="up_item.up_type" /></td>
					<td><date-time-format :datetime="up_item.created_time" /></td>
					<td><field-value name="created_by" :value="up_item.created_by" /></td>
					<td><date-format :date="up_item.expires" past-class="red white--text pa-1 rounded my-1" /></td>
					<td>{{ device_item.device_N }}</td>
					<td>{{ device_item.reason }}</td>
					<td><boolean-indicator :value="device_item.is_processed" colored /></td>
					<td><boolean-indicator :value="device_item.is_fixed" colored /></td>
					<td>
						<v-btn v-if="up_item.file" :href="up_item.file.url" target="_blank" small text>
							{{ up_item.file.name }}
						</v-btn>
					</td>
					<td class="text-right" width="1%" v-if="editPermission">
						<UPEditPopup
							:id="up_item.id"
							@reload="$emit('reload')"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn right icon small v-bind="attrs" v-on="on" tabindex="-1">
									<v-icon color="primary" size="24">mdi-pencil-circle</v-icon>
								</v-btn>
							</template>
						</UPEditPopup>
					</td>
				</tr>
				<tr v-else :key="`${up_index}_${device_index}`">
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td>{{ device_item.device_N }}</td>
					<td>{{ device_item.reason }}</td>
					<td><boolean-indicator :value="device_item.is_processed" colored /></td>
					<td><boolean-indicator :value="device_item.is_fixed" colored /></td>
					<td></td>
					<td v-if="editPermission"></td>
				</tr>
			</template>
		</template>
		</tbody>
	</v-simple-table>
</template>

<script>
import BooleanIndicator from "@/ittijs/components/BooleanIndicator.vue";
import DateTimeFormat from "@/ittijs/components/DateTimeFormat.vue";
import DateFormat from "@/ittijs/components/DateFormat.vue";
import UPEditPopup from "@/ittijs/components/UvedomitelniPisma/UPEditPopup.vue";
import FieldLabel from "@/ittijs/components/FieldLabel.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "./Model";
import FieldValue from "@/ittijs/components/FieldValue.vue";
import {RolesEnum} from "@/ittijs/Authorization";

const imodel = ITTIModelProvider.getModel(Model);

export default {
	components: {
		FieldValue,
		FieldLabel,
		UPEditPopup,
		DateFormat,
		DateTimeFormat,
		BooleanIndicator,
	},
	inject: [
		'authorization',
	],
	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		editPermission(){
			return this.authorization.hasRole(RolesEnum.UVEDOMITELNI_PISMA);
		},
	},
	provide: {
		imodel,
	},
}
</script>